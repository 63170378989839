export const getDomainText = (key) => {
  const hostname = window.location.hostname;
  switch (hostname) {
    case 'aconcagua.app':
      switch (key) {
        case 'companyName':
          return 'Aconcagua';
        case 'companySlogan':
          return 'Reaching New Heights';
        case 'companyURL':
          return 'https://aconcagua.finance';
        case 'loginImageLight':
          return 'login-v3.png';
        case 'loginImageDark':
          return 'login-v3.png';
        case 'logoSourceKey':
          return 'Aconcagua';
        case 'logoPathKey':
          return 'Aconcagua';
        case 'scssPathKey':
          return 'Aconcagua';
        default:
          return '';
      }
    case 'bishorn.xyz':
      switch (key) {
        case 'companyName':
          return 'Bishorn';
        case 'companySlogan':
          return 'Climbing Together';
        case 'companyURL':
          return 'https://bishorn.xyz';
        case 'loginImageLight':
          return 'login-blank.png';
        case 'loginImageDark':
          return 'login-blank.png';
        case 'logoSourceKey':
          return 'Bishorn';
        case 'logoPathKey':
          return 'Bishorn';
        case 'scssPathKey':
          return 'Bishorn';
        default:
          return '';
      }
    case 'aconcaguapsav.web.app':
      switch (key) {
        case 'companyName':
          return 'Bishorn';
        case 'companySlogan':
          return 'Climbing Together';
        case 'companyURL':
          return 'https://bishorn.xyz';
        case 'loginImageLight':
          return 'login-blank.png';
        case 'loginImageDark':
          return 'login-blank.png';
        case 'logoSourceKey':
          return 'Bishorn';
        case 'logoPathKey':
          return 'Bishorn';
        case 'scssPathKey':
          return 'Bishorn';
        default:
          return '';
      }
    case 'catedral-fb.web.app':
      switch (key) {
        case 'companyName':
          return 'Aconcagua';
        case 'companySlogan':
          return 'Reaching New Heights';
        case 'companyURL':
          return 'https://catedral-fb.web.app';
        case 'loginImageLight':
          return 'login-v3.png';
        case 'loginImageDark':
          return 'login-v3.png';
        case 'logoSourceKey':
          return 'Aconcagua';
        case 'logoPathKey':
          return 'Aconcagua';
        case 'scssPathKey':
          return 'Aconcagua';
        default:
          return '';
      }
    case 'catedral-bishorn.web.app':
      switch (key) {
        case 'companyName':
          return 'Bishorn';
        case 'companySlogan':
          return 'Climbing Together';
        case 'companyURL':
          return 'https://catedral-bishorn.web.app';
        case 'loginImageLight':
          return 'login-blank.png';
        case 'loginImageDark':
          return 'login-blank.png';
        case 'logoSourceKey':
          return 'Bishorn';
        case 'logoPathKey':
          return 'Bishorn';
        case 'scssPathKey':
          return 'Bishorn';
        default:
          return '';
      }
    case 'use-lanin.web.app':
      switch (key) {
        case 'companyName':
          return 'Aconcagua';
        case 'companySlogan':
          return 'Reaching New Heights';
        case 'companyURL':
          return 'https://use-lanin.web.app';
        case 'loginImageLight':
          return 'login-v3.png';
        case 'loginImageDark':
          return 'login-v3.png';
        case 'logoSourceKey':
          return 'Aconcagua';
        case 'logoPathKey':
          return 'Aconcagua';
        case 'scssPathKey':
          return 'Aconcagua';
        default:
          return '';
      }
    case 'lanin-bishorn.web.app':
      switch (key) {
        case 'companyName':
          return 'Bishorn';
        case 'companySlogan':
          return 'Climbing Together';
        case 'companyURL':
          return 'https://lanin-bishorn.web.app';
        case 'loginImageLight':
          return 'login-blank.png';
        case 'loginImageDark':
          return 'login-blank.png';
        case 'logoSourceKey':
          return 'Bishorn';
        case 'logoPathKey':
          return 'Bishorn';
        case 'scssPathKey':
          return 'Bishorn';
        default:
          return '';
      }
    case 'localhost':
      switch (key) {
        case 'companyName':
          return 'Bishorn';
        case 'companySlogan':
          return 'Slogan Local';
        case 'companyURL':
          return 'http://localhost:3006';
        case 'loginImageLight':
          return 'login-blank.png';
        case 'loginImageDark':
          return 'login-blank.png';
        case 'logoSourceKey':
          return 'Local';
        case 'logoPathKey':
          return 'Local';
        case 'scssPathKey':
          return 'Local';
        default:
          return '';
      }
    default:
      return '';
  }
};

// Define all possible logo paths
export const logoPaths = {
  Aconcagua: require('@src/assets/images/logo/logo.png'),
  Bishorn: require('@src/assets/images/logo/logo_bishorn.png'),
  Local: require('@src/assets/images/logo/logo_bishorn.png'),
};

export const spinnerPaths = {
  Aconcagua: require('@src/assets/images/logo/spinner.gif'),
  Bishorn: require('@src/assets/images/logo/spinner_bishorn.gif'),
  Local: require('@src/assets/images/logo/spinner_bishorn.gif'),
};

export const smallSpinnerPaths = {
  Aconcagua: require('@src/assets/images/logo/spinner.gif'),
  Bishorn: require('@src/assets/images/logo/spinner_bishorn.gif'),
  Local: require('@src/assets/images/logo/spinner_bishorn.gif'),
};
